.OrderTemplateDesc * {
    padding: 0;
    margin: 0 !important
}

.OrderTemplatemain {
    padding: 0;
    box-sizing: border-box;
    margin: 14px 0px;
    padding: 14px;
    border-radius: 20px;
    background: url("../../icons/rectangle.png");
    position: relative;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    margin: 8px;

}

.OrderTemplateImage span {
    padding: 6px;
    border-radius: 50%;
    width: 35px;
    background: blue;
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 500;

}



.OrderTemplateDesc {
    padding: 3px 6px 3px 12px;
    width: 89%;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 5px;
    /* border: 1px solid rgb(4, 245, 104); */
}

.OrderTemplateDesc span {
    font-weight: 700;
    color: black;
  

}

.OrderTemplateedit {
    position: absolute;
    right: 10px;
    top: 10px;
    /* background-color: yellow; */
    /* border-radius: 50%; */
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 4px;
    /* height: 40px; */
}

.OrderTemplateedit p:nth-child(1) {
    color: rgb(32, 122, 32);
    font-weight: 700;
}

.OrderTemplateedit p:nth-child(2) {
    color: black;
    font-weight: 600;
}

.addressMain {
    padding: 0;
    box-sizing: border-box;
    margin: 14px 0px;
    padding: 14px;
    border-radius: 20px;
    border: 1px solid grey;
    position: relative;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    margin: 8px;
    padding-top: 20px;
}

.addressMain div div {
    display: flex;
    flex-wrap: wrap;

}

.addressMain div div p {
    width: 50%;
}

.addressMain div div p span {
    font-size: 13px;
    font-weight: 700;
    color: black;
    text-transform: capitalize;
}

.addressMain>div div:nth-child(1) button {
    width: 50%;
    height: 42px;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 14px;

}

.addressMain>div div:nth-child(1) button:nth-child(1) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;


}

.addressMain>div div:nth-child(1) button:nth-child(2) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

}

.addressMain div:nth-child(2) {
    margin-top: 20px;
}

.addressMain div div:last-child p {
    width: 85%;
}

.addressMain>div div:nth-child(1) {
    border: 2px solid blue;
    border-radius: 8px;


}

.blueButton {
    background-color: blue;
    color: white;
    font-size: 13px;
}

.whiteButton {
    background-color: white;
    color: blue;
    font-size: 13px;
}

@media screen and (max-width: 786px) {
    .OrderTemplatemain {
        display: flex;
    }

    /* .OrderTemplateDesc p {
        display: inline-block;
    } */
}

.labels{
    font-weight: 700;
    color: black;
    font-size: 14px;
    margin-left: 5px;
}

@media screen and (max-width: 375px) {

    .OrderTemplateDesc {
        gap: 5px;
    }


    .OrderTemplateImage img {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
    }

    .OrderTemplateDesc p:nth-child(3) {
        padding-bottom: 0px
    }


}

@media screen and (max-width: 375px) {
    .OrderTemplatemain {
        padding: 7px;
    }
}

@media screen and (max-width: 320px) {
    .OrderTemplateDesc div:nth-child(3) p {

        font-size: 11px;
    }

    .OrderTemplateDesc div:nth-child(1) {
        font-size: 14px;
    }

    .OrderTemplateDesc div:nth-child(2) p {
        font-size: 14px;
    }

    .addressMain div div:not(:nth-child(1)) {
        flex-direction: column;
    
    }

   
}