.templateTemplateDesc * {
    padding: 0;
    margin: 0 !important
}

.templateTemplatemain {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* height: 150px; */
    /* background-color: rgb(156, 99, 99); */
    margin: 14px 0px;
    display: none;
    padding: 14px;   
    border-radius: 20px;
    /* border: 1px solid red; */
    background: url("../../icons/rectangle.png");
    position: relative;
    background-repeat: no-repeat;
    background-position: right;
}

.templateTemplateImage span {
    /* border: 1px solid red; */
    /* display: inline-block; */
    /* border: 1px solid red; */
    padding: 6px;
    border-radius: 50%;
    width: 35px;
    background: blue;
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 500;

}



.templateTemplateDesc {
    width: 89%;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 5px;
    /* border: 1px solid rgb(4, 245, 104); */
}

.templateTemplateDesc div:nth-child(1) p:nth-child(2) {
    font-weight: 700;
    color: black;
}

.templateTemplateDesc div:nth-child(3) p {
    width: 100%;
    font-size: 13px;
}

.templateTemplateDesc div:nth-child(3) p span:nth-child(2) {
    color: black;
    font-weight: 700;
    /* font-size: 15px; */
}



.templateTemplateDesc div:nth-child(1) p:nth-child(1) {
    color: #001AD5;
    font-weight: 500;
    font-size: 15px;

}

.templateTemplateDesc p:nth-child(1) {
    color: #4F4F4F;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

}

.templateTemplateedit {
    position: absolute;
    right: 10px;
    top: 10px;
    /* background-color: yellow; */
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 786px) {
    .templateTemplatemain {
        display: block;
    }

    .templateTemplateDesc p {
        display: inline-block;
    }
}

@media screen and (max-width: 375px) {

    .templateTemplateDesc {
        gap: 5px;
    }


    .templateTemplateImage img {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
    }

    .templateTemplateDesc p:nth-child(3) {
        padding-bottom: 0px
    }


}

@media screen and (max-width: 375px) {
    .templateTemplatemain {
        padding: 7px;
    }
}

@media screen and (max-width: 320px) {
    .templateTemplateDesc div:nth-child(3) p {

        font-size: 11px;
    }

    .templateTemplateDesc div:nth-child(1) {
        font-size: 14px;
    }

    .templateTemplateDesc div:nth-child(2) p {
        font-size: 14px;
    }
}