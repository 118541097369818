.transactionListing_mobile {
  display: none;
  min-height: 70vh;
}



.inputs {
  display: none;
}

.sellerListOptions {
  /* border: 1px solid red; */
  width: 46%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b9bbc0;
  padding-left: 20px;
  color: black;
  font-weight: 300;
  border-radius: 5px;
  margin-top: 10px;
}

.sellerListOptions select {
  background-color: blue;
  width: 187px;
  margin: 0 !important;
  color: white;
  font-weight: 500;
}

.sellerListOptions select option {
  background-color: #f8f9fc;
  color: black;
}

.selectionControl {
  position: relative;
}

.selectionControl::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}


@media screen and (max-width: 768px) {
  .sellerListOptions {
    width: 97%;
    font-size: 12px;
  }
  .transactionListing_desktop {
    display: none;
  }

  .orderDesktopSearches {
    display: none;
  }


  .transactionListing_mobile {
    display: block;
    padding: 10px;
    background-color: #F5F5F5;
  }

  .cardbody {
    background-color: #F5F5F5;
    padding: 15px !important;
  }

  .inputs_desktop {
    display: none;
  }

  .inputs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
  }

}

@media screen and (max-width: 375px) {
  .sellerListOptions {
    /* width: 46%; */
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .sellerListOptions {
    width: 100%;
    font-size: 11px;
  }

  /* .sellerListOptions select {
    width: 33px;
  } */
}