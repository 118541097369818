@font-face {
    font-family: 'SF Pro Display';
    src: url('../../../fonts/SFPRODISPLAYREGULAR.OTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.mobile_navbar {
    background-color: white;
}

.burgerButton{
    display: none;
}

.logo {
    display: none
}

.searchOpener {
    display: none;
}

.doropdownnavmenu {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .plus{
        position: static !important;
    }
    .mobile_navbar {
        background-color: #0094ff;
        height: 45px;
        display: flex;
        justify-content: space-between;
    }

    .burgerButton{
        display: block;
    }

    .logo {
        display: block;
        color: white;
        font-weight: 500;
        font-size: 15px;
        line-height: 17px;
        font-family: 'SF Pro Display';
        margin: 0 auto
    }

    .logo img {
        width: 30px;
        margin-right: 4px;
    }

    .searchOpener {
        display: block;
        font-size: 30px;
        color: white;
    }


    .dropdownimg {
        display: none;
    }

    .doropdownnavmenu {
        flex-direction: row !important;
        height: 80px;
        background-color: #006ed6;
        font-size: 27px;
        justify-content: center;
        align-items: center;
        gap: 74px;
    }

    .doropdownnavmenu i {
        font-size: 26px !important;
        color: white !important;
    }

    .doropdownnavmenu span {
        display: none;

    }



}

@media screen and (max-width: 560px){
    .doropdownnavmenu{
        gap: 14px;
    }
}


@media screen and (max-width: 330px) {
    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 9px;
        font-size: 13px;

    }

    .logo img {
        width: 35px;
    }

    .doropdownnavmenu{
        gap: 1px;
    }

}