@font-face {
    font-family: 'SF Pro Display';
    src: url('../../fonts/SFPRODISPLAYREGULAR.OTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* SFProDisplay-Regular.ttf */


.mobile_dashboard {
    min-height: 80vh;
}

.mobile_dashboard .heading {
    /* background-color: white; */
    /* color was text-grey-800  */
    color: grey;
    padding-top: 10px;
}

.count {
    color: #0094ff;
    /* color was text-grey-800  */
    color: grey
}

.iconColor i {
    /* color was text-grey-300  */
    color: grey
}


.mobile_dashboard .heading span {
    display: none;
}


.icn::before {
    content: "\f155";
}

.perforamanceFlow {
    display: none;
}

.doropdownnavmenu {
    display: flex;
    flex-direction: column;
}



.iconColor img {
    /* color was text-grey-300  */
    width: 90%;
    animation-name: floatingIcons;
    animation-duration: 1s;
}

@keyframes floatingIcons {
    0% {
        transform: translate(-220px) scale(0);
        /* transform: scale(0); */
        opacity: 0;
    }

    100% {
        transform: translate(0px) scale(1);
        /* transform: scale(1); */
        opacity: 1;
    }
}


@media screen and (max-width: 768px) {
    .mobile_dashboard {
        min-height: auto;
    }

    .mobile_dashboard .heading h1 {
        color: #0094ff;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        font-family: 'SF Pro Display';
    }

    .mobile_dashboard .heading span {
        display: block;
        color: black;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-family: 'SF Pro Display';
        letter-spacing: 2px;

    }

    .count {
        color: #2392FF;
        font-weight: 700;
        font-size: 32px;
        line-height: 38.19px;
        padding-top: 20px;
        padding-bottom: 10px;
    }


    .cardHeading {
        color: black;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
    }

    .cardSttyle {
        border: none !important;
        border-radius: 20px;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .cardSttyle>div {
        padding: 0;
        padding-top: 11px;
    }

    .perforamanceFlow {
        display: block;
        color: grey;
        font-family: 'SF Pro Display';
        font-size: 16px;
    }




}

@media screen and (max-width: 330px) {
    .iconColor i {
        /* color was text-grey-300  */
        color: #2392FF;
        font-size: 50px
    }
}