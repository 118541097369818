.OrderTemplateDesc * {
    padding: 0;
    margin: 0 !important
}

.OrderTemplatemain {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* height: 150px; */
    /* background-color: rgb(156, 99, 99); */
    margin: 14px 0px;
    display: none;
    padding: 14px;
    border-radius: 20px;
    /* border: 1px solid red; */
    background: url("../../icons/rectangle.png");
    position: relative;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;

}

.OrderTemplateImage span {
    /* border: 1px solid red; */
    /* display: inline-block; */
    /* border: 1px solid red; */
    padding: 6px;
    border-radius: 50%;
    width: 35px;
    background: blue;
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 500;

}



.OrderTemplateDesc {
    width: 89%;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 5px;
    /* border: 1px solid rgb(4, 245, 104); */
}

.OrderTemplateDesc div:nth-child(1) p:nth-child(2) {
    font-weight: 700;
    color: black;
}

.OrderTemplateDesc div:nth-child(3) p {
    width: 100%;
    font-size: 13px;
}

.OrderTemplateDesc div:nth-child(3) p span:nth-child(2) {
    color: black;
    font-weight: 700;
    /* font-size: 15px; */
}



.OrderTemplateDesc div:nth-child(1) p:nth-child(1) {
    color: #001AD5;
    font-weight: 500;
    font-size: 15px;

}

.OrderTemplateDesc p:nth-child(1) {
    color: #4F4F4F;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

}

.OrderTemplateedit {
    position: absolute;
    right: 10px;
    top: 10px;
    /* background-color: yellow; */
    /* border-radius: 50%; */
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 4px;
    /* height: 40px; */
}

.OrderTemplateedit p:nth-child(1) {
    color: rgb(32, 122, 32);
    font-weight: 700;
}

.OrderTemplateedit p:nth-child(2) {
    color: black;
    font-weight: 600;
}

@media screen and (max-width: 786px) {
    .OrderTemplatemain {
        display: block;
    }

    .OrderTemplateDesc p {
        display: inline-block;
    }
}

@media screen and (max-width: 375px) {

    .OrderTemplateDesc {
        gap: 5px;
    }


    .OrderTemplateImage img {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
    }

    .OrderTemplateDesc p:nth-child(3) {
        padding-bottom: 0px
    }


}

@media screen and (max-width: 375px) {
    .OrderTemplatemain {
        padding: 7px;
    }
}

@media screen and (max-width: 320px) {
    .OrderTemplateDesc div:nth-child(3) p {

        font-size: 11px;
    }

    .OrderTemplateDesc div:nth-child(1) {
        font-size: 14px;
    }

    .OrderTemplateDesc div:nth-child(2) p {
        font-size: 14px;
    }

    .OrderTemplateedit {
        gap: 22px;
    }
}