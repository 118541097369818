.pagination_main{
    /* border: 1px solid red */
    margin-top: 20px;
}

.pagination_main ul{
    display: flex;
    justify-content: center;
    gap: 3px    ;
    align-items: center;

}

.pagination_main ul li a{
    list-style: none;
    /* border: 1px solid black; */
    background-color: #4e73df;
    color: white;
    font-weight: 600;
    /* margin-left: 3px; */
    /* padding: 2px 5px; */
    border-radius: 50%;
    width: 33px;
    height: 33px;
    /* margin: 0 auto; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination_main ul li {
    color: white;
    list-style: none;
}


.current_active{
    background-color: darkblue !important;
}

@media screen and (max-width: 321px) {
    .pagination_main ul li a{
        width: 25px;
        height: 25px;
    }
    
}