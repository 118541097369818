.pop-up {
  position: absolute;
  top: 0%;
  /* left: 0%; */
  /* transform: translate(-50%, -50%); */
  /* transform: translate(-35%, -105%); */
  transform: translate(-27%, -95%);
  background-color: white;
  /* padding: 20px; */
  border-radius: 10px;
  box-shadow: 0px 0px 10px #333;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pop-up-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productListing_mobile {
  display: none;
}

.productListing_desktop {
  display: block;
}

.inputs {
  display: none;
}

.cardBodyHeight {
  min-height: 80vh;
}



@media screen and (max-width: 768px) {
  .productListing_mobile {
    display: block;
  }

  .productListing_desktop {
    display: none;
  }

  .importingSection {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 15px;
  }

  .inputs_desktop {
    display: none;
  }

  .inputs {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .pop-up {
    right: 12px;
    width: 187px;padding: 6px 0px;
  }

  .multiselectdivision {
    border: 1px solid lightgray;
  }

  .multiselectdivision div ul li {
    list-style: none;

  }
}