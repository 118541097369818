.smallScreenSidebar {
    display: none;
}

/* .bigScreenSidebar{
    display: none;
} */

@media screen and (max-width: 768px) {
    .smallScreenSidebar {
        display: block;
    }

    .bigScreenSidebar {
        display: none;
    }
}