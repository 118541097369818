.thanks_container{
    width: 100%;
    /* border: 1px solid red; */
    height: 100%;
    /* background-image: url(../../icons/hurray.png); */
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    
}



/* .thanks_container > div img{
    width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
} */

.thanks_statement p{
    text-align: center;
}
.thanks_statement p:nth-child(1){
    color: black;
    font-size: 17px;
    font-weight: 600;
    

}




.thanks_button button{
    background-color: #0094ff !important;
    
    
}