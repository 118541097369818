.tab_buttons{
    background-color: #0094ff;
    /* border: none; */
    outline: none;
    margin: 2px;
    padding: 6px;
   

    
   
}


.tabs {
    display: flex;
    flex-direction: row;
    background-color: white;
    justify-content: space-between;
  }
  
  .tab {
    height: 0;
    width: 133px;
    border-bottom: 35px solid #CCCCCC;
    border-right: 21px solid transparent;
    border-top-left-radius: none;
    box-sizing: border-box;
    /* display: block; */
    background: white;
    font-size: 14px;
    outline: none;
    line-height: 27px;
    border-top: none;
    border-left: none;
    font-size: 13px;
    border-top-left-radius: 6px;
    font-weight: 600;
  }


  
  .tab:not(:first-child) {
      /* margin-left: -10px; */
      z-index: 0;
  }
  
  .tab .label{
    padding: 8px;
    text-align: center;
    color: #444444;
    font-weight: 600;
 
  }
  
  .active {
    border-bottom: 35px solid #0094ff;
    color: white;
  }
  .active .label{
    color: #ffffff;
  }